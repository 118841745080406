
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandPunch from '@/mixins/mixinDemandPunch';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import header from "@/vuex/header";
import DemandpunchTableDaterow from "@/components/demandpunch/table/Daterow.vue";

@Component({
  components: {
    DemandpunchTableDaterow,
  }
})
export default class Table extends Mixins(mixinDemandPunch) {
  get date_array_with_holiday() {
    return util.getSpanDateArrayNationalHoliday(this.header.national_holidays, this.global.payment_info.close_started_on, this.global.payment_info.close_ended_on);
  }

  get max_row_per_date() {
    var max_row_per_date = {};
    this.date_array_with_holiday.forEach(date_with_holiday => {
      max_row_per_date[date_with_holiday.date] = 0;
      if (this.shifts[date_with_holiday.date])
        this.shifts[date_with_holiday.date].forEach(shift => {
          if (this.m.edit_shift_flag == shift.id)
            max_row_per_date[date_with_holiday.date] += Math.max(this.m.shift.shift_shift.length, this.m.shift.shift_punch.length, this.m.shift.shift_break.length);
          else
            max_row_per_date[date_with_holiday.date] += Math.max(shift.shift_shift.length, shift.shift_punch.length, shift.shift_break.length);
        });
        if (this.m.add_shift_flag == date_with_holiday.date) {
          max_row_per_date[date_with_holiday.date] += Math.max(this.m.shift.shift_shift.length, this.m.shift.shift_punch.length, this.m.shift.shift_break.length);
        }
      if (this.demand_punches[date_with_holiday.date])
        Object.keys(this.demand_punches[date_with_holiday.date]).forEach(branch_id => {
          if (this.demand_punches[date_with_holiday.date][branch_id].type != 2)
            max_row_per_date[date_with_holiday.date] += Math.max(this.demand_punches[date_with_holiday.date][branch_id].demand_punch_punch.length, this.demand_punches[date_with_holiday.date][branch_id].demand_punch_break.length);
        });
    });
    return max_row_per_date;
  }

  get row_count() {
    var sum = 0;
    Object.keys(this.max_row_per_date).forEach(date => {
      sum += this.max_row_per_date[date];
    });
    return sum;
  }

  get shifts() {
    return this.monthly.shifts;
  }

  get holidays() {
    return this.monthly.holiday_hash;
  }

  get carfares() {
    return this.monthly.carfare_hash;
  }
}
