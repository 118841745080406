
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandPunch from '@/mixins/mixinDemandPunch';
import global from "@/vuex/attendance_each/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance_each/manager";
import CommonProgress from "@/components/common/Progress.vue";
import DemandpunchTableHead from "@/components/demandpunch/table/Head.vue";
import DemandpunchTableTable from "@/components/demandpunch/table/Table.vue";

@Component({
  components: {
    CommonProgress,
    DemandpunchTableHead,
    DemandpunchTableTable,
  }
})
export default class Table extends Mixins(mixinDemandPunch) {
}
