import { Component, Vue, Mixins, Watch } from 'vue-property-decorator';
import monthly_demand from "@/vuex/demand_punch/monthly_demand";
import monthly from "@/vuex/attendance_each/monthly";
import global from "@/vuex/attendance_each/global";
import * as util from '@/util';
import utilMixins from '@/mixins';
import manager from '@/vuex/demand_punch/manager';

@Component
export default class mixinDemandPunch extends Mixins(utilMixins) {
  changed_disp_num: boolean = false;

  get is_set_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_set;
  }
  get is_set_monthly(): boolean {
    return monthly.data[this.date] && monthly.data[this.date][this.employee_id] && monthly.data[this.date][this.employee_id].is_set;
  }
  get is_set_monthly_demand(): boolean {
    return monthly_demand.data[this.date] && monthly_demand.data[this.date][this.employee_id] && monthly_demand.data[this.date][this.employee_id].is_set;
  }

  get is_valid_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_valid;
  }
  get is_valid_monthly(): boolean {
    return monthly.data[this.date] && monthly.data[this.date][this.employee_id] && monthly.data[this.date][this.employee_id].is_valid;
  }
  get is_valid_monthly_demand(): boolean {
    return monthly_demand.data[this.date] && monthly_demand.data[this.date][this.employee_id] && monthly_demand.data[this.date][this.employee_id].is_valid;
  }

  async get_global() {
    await global.fetch({yearmonth: this.date});
  }
  async get_monthly() {
    await monthly.fetch({yearmonth: this.date, branch_id: this.viewing_branch, employee_id: this.employee_id, payment_info_id: this.global.payment_info.id});
  }
  async get_monthly_demand() {
    await monthly_demand.fetch({yearmonth: this.date, branch_id: this.viewing_branch, employee_id: this.employee_id, payment_info_id: this.global.payment_info.id});
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) 
      return global.data[this.date];
  }
  get monthly() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();//get_monthlyでglobalを使用するため
    else if (!this.is_set_monthly || !this.is_valid_monthly) this.get_monthly();
    if (this.is_set_monthly)
      return monthly.data[this.date][this.employee_id];
  }
  get monthly_demand() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();//get_monthly_demandでglobalを使用するため
    else if (!this.is_set_monthly_demand || !this.is_valid_monthly_demand) this.get_monthly_demand();
    if (this.is_set_monthly_demand)
      return monthly_demand.data[this.date][this.employee_id];
  }


  get payment_info() {
    return this.global.payment_info;
  }

  get employee_id() {
    if (this.query.employee_id === undefined)
      return Number(this.s_employee_id);
    else
      return this.query.employee_id;
  }

  get employee() {
    if (this.monthly) {
      return this.monthly.employee;
    }
  }

  get each_attendance() {
    if (this.monthly) {
      return this.monthly.each_attendance;
    }
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage('branch_id'));
      this.query = {viewing_branch: branch_id};
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.query = {viewing_branch: val};
  }

  get m() {
    return manager;
  }
  
  get todayobj() {
    return new Date();
  }

  get dateobj() {
    return util.date2obj(this.date+"-01");
  }
  get date() {
    var date = this.query.demandpunch_date;
    if (date === undefined) {
      date = util.formatDate(new Date(), 'yyyy-mm');
      this.query = {demandpunch_date: date};
    }
    return date;
  }
  set date(val) {
    if (val != this.date) {
      manager.cancelAll();
      this.query_push = {demandpunch_date: val};
    }
  }


  get branch_hash() {
    return this.monthly.branch_hash;
  }
  get branch_hash_all() {
    return this.monthly.branch_hash_all;
  }
  get demand_punches() {
    return this.monthly_demand.demand_punches;
  }
  get lock_types_hash() {
    return this.monthly.lock_types_hash;
  }

  get is_locked() {
    return this.lock_types_hash[this.employee.branch_id] != 0;
  }


  get show_punch() {
    return this.is_manager || this.monthly.show_config.show_punch_round != 1;
  }
  get show_punch_round() {
    return this.is_manager || this.monthly.show_config.show_punch_round != 0;
  }

  get show_break() {
    return this.is_manager || this.monthly.show_config.show_break_round != 1;
  }
  get show_break_round() {
    return this.is_manager || this.monthly.show_config.show_break_round != 0;
  }
}