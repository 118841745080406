
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandPunch from '@/mixins/mixinDemandPunch';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonProgress from "@/components/common/Progress.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonProgress,
    CommonButton,
  }
})
export default class Daterow extends Mixins(mixinDemandPunch) {
  @Prop() is_first: boolean;
  @Prop() dayprop: number;
  @Prop() dateprop: string;
  @Prop() carfare;
  @Prop() demand_punch;
  @Prop() is_holiday: boolean;
  @Prop() work_type: number;
  @Prop() max_row_per_date: number;


  get is_first_row() {
    return this.is_first && this.m.add_shift_flag != this.dateprop;
  }

  get work_type_value() {
    return this.work_type;
  }

  get datestr() {
    const dateobj = util.date2obj(this.dateprop);
    return util.formatDate(dateobj, 'mm月dd日');
  }
  get daystr() {
    return util.day2weekstr(this.dayprop);
  }

  get max_row_per_branch() {
    return Math.max(this.demand_punch.demand_punch_punch.length, this.demand_punch.demand_punch_break.length)
  }

  cancel_shift(demand_punch_id) {
    this.m.cancelShift({branch_id: this.demand_punch.branch_id, date: this.dateprop, yearmonth: this.date, employee_id: this.employee_id, demand_punch_id});
  }
}
