
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandPunch from '@/mixins/mixinDemandPunch';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Daterow extends Mixins(mixinDemandPunch) {
  @Prop() dayprop: number;
  @Prop() dateprop: string;
  @Prop() carfare;
  @Prop() is_holiday: boolean;
  @Prop() work_type: number;

  get work_type_value() {
    return this.work_type;
  }

  get datestr() {
    const dateobj = util.date2obj(this.dateprop);
    return util.formatDate(dateobj, 'mm月dd日');
  }
  get daystr() {
    return util.day2weekstr(this.dayprop);
  }
}
