
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinDemandPunch from "@/mixins/mixinDemandPunch";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Shift from "@/typesold/shift";
  import Able from "@/typesold/able";
  import CommonTime from "@/components/common/Time.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonTime,
      CommonButton,
    },
  })
  export default class Inputrow extends Mixins(mixinDemandPunch) {
    @Prop() editshift;

    @Prop() shifts;
    @Prop() index: number;
    @Prop() dayprop: number;
    @Prop() dateprop: string;
    @Prop() carfare;
    @Prop() is_holiday: boolean;
    @Prop() work_type: number;
    @Prop() max_row_per_date: number;

    get shift() {
      return this.m.shift;
    }
    set shift(val) {
      this.m.setShift({ shift: val });
    }
    get branch_month_hash() {
      if (this.monthly) {
        const branch_hash = {};
        Object.keys(this.monthly.branch_hash).forEach((branch_id) => {
          if (!this.shifts.find((shift) => shift.branch_id == Number(branch_id)))
            branch_hash[branch_id] = this.monthly.branch_hash[branch_id];
        });
        return branch_hash;
      }
    }
    get work_type_value() {
      return this.work_type;
    }

    @Watch("is_add")
    on_change_is_add(newval, oldval) {
      if (newval && !oldval) {
        this.shift = {
          id: null,
          date: null,
          branch_id: null,
          employee_id: null,
          shift_shift: [],
          shift_punch: [],
          shift_break: [],
        };
        this.punch_input();
        this.break_input();

        //初期値として店舗を入れておく
        if (Object.keys(this.branch_month_hash).length != 0) {
          if (this.branch_month_hash[this.employee.branch_id]) {
            this.shift.branch_id = this.employee.branch_id;
          } else {
            this.shift.branch_id = Object.keys(this.branch_month_hash)[0];
          }
        }
      }
    }

    @Watch("is_edit")
    on_change_is_edit(newval, oldval) {
      if (newval && !oldval) {
        this.shift = {
          id: this.editshift.id,
          date: this.dateprop,
          branch_id: this.editshift.branch_id,
          employee_id: this.editshift.employee_id,
          shift_shift: util.deep_copy(this.editshift.shift_shift),
          shift_punch: util.deep_copy(this.editshift.shift_punch),
          shift_break: util.deep_copy(this.editshift.shift_break),
        };
        this.punch_input();
        this.break_input();
      }
    }

    create_shift() {
      this.m.createShift({
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
      });
    }
    update_shift() {
      this.m.updateShift({
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
      });
    }

    get is_edit() {
      return this.editshift && this.m.edit_shift_flag == this.editshift.id;
    }
    get is_add() {
      return !this.editshift && this.m.add_shift_flag == this.dateprop;
    }

    punch_input() {
      this.shift.shift_punch = this.time_input(this.shift.shift_punch);
    }
    break_input() {
      this.shift.shift_break = this.time_input(this.shift.shift_break);
    }

    time_input(shift_times) {
      const length = shift_times.length - 1;
      shift_times = shift_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (shift_times.length == 0) {
        shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
      } else {
        const shift_time = shift_times[shift_times.length - 1];
        if (shift_time.started_at != null || shift_time.ended_at != null) {
          shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
        }
      }
      return shift_times;
    }

    get is_first_row() {
      return (
        (this.index !== undefined && this.index === 0) || this.m.add_shift_flag == this.dateprop
      );
    }

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      return util.formatDate(dateobj, "mm月dd日");
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }

    get max_row_per_branch() {
      return Math.max(
        this.shift.shift_shift.length,
        this.shift.shift_punch.length,
        this.shift.shift_break.length,
      );
    }
  }
