
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinDemandPunch from '@/mixins/mixinDemandPunch';
import DemandpunchEmployeechanger from "@/components/demandpunch/Employeechanger.vue";
import DemandpunchMonthchanger from "@/components/demandpunch/Monthchanger.vue";
import DemandpunchTableMain from "@/components/demandpunch/table/Main.vue";

@Component({
  components: {
    DemandpunchEmployeechanger,
    DemandpunchMonthchanger,
    DemandpunchTableMain,
  }
})
export default class Attendance_each extends Mixins(mixinDemandPunch) {
}
