
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandPunch from '@/mixins/mixinDemandPunch';
import dialog from "@/vuex/dialog";

@Component({
  components: {
  }
})
export default class Employeechanger extends Mixins(mixinDemandPunch) {
  get employee_name() {
    if (!this.cannot_show)
      return this.employee.name+"さんの";
    else
      return '';
  }

  get cannot_show() {
    return !this.global || !this.monthly;
  }
}
