
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDemandPunch from '@/mixins/mixinDemandPunch';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import DemandpunchTableInputrow from "@/components/demandpunch/table/Inputrow.vue";
import DemandpunchTableNobranchrow from "@/components/demandpunch/table/Nobranchrow.vue";
import DemandpunchTableBranchrow from "@/components/demandpunch/table/Branchrow.vue";
import DemandpunchTableDemandpunchrow from "@/components/demandpunch/table/Demandpunchrow.vue";

@Component({
  components: {
    DemandpunchTableInputrow,
    DemandpunchTableNobranchrow,
    DemandpunchTableBranchrow,
    DemandpunchTableDemandpunchrow,
  }
})
export default class Daterow extends Mixins(mixinDemandPunch) {
  @Prop() dateprop: string;
  @Prop() dayprop: number;
  @Prop() is_holiday: boolean;
  @Prop() work_type: number;
  @Prop() carfare;
  @Prop() max_row_per_date: number;
  @Prop() shifts;

  get datestr() {
    const dateobj = util.date2obj(this.dateprop);
    return util.formatDate(dateobj, 'mm月dd日');
  }

  get is_demand_add() {
    var is_demand_add = false;
    if (this.demand_punches[this.dateprop]) {
      Object.keys(this.demand_punches[this.dateprop]).forEach(branch_id => {
        if (this.demand_punches[this.dateprop][branch_id].type == 0) {
          is_demand_add = true;
        }
      });
    }
    return is_demand_add;
  }
}
